@import "../../assets/scss/abstract/abstract";

.offerlistOuterContainer {
  background: var(--yps-bg-color-secondary);
  position: relative;
  border-top: 1px solid #dddddd;
  padding: 0 20px;
  @include mobile {
    padding: 0;
    border-top: 0;
  }
  // margin: 0 -20px;
}


.offerlistContainer {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  @include desktop {
    grid-template-columns: 300px minmax(0, 1fr);
  }
  @include small {
    grid-template-columns: 280px minmax(0, 1fr);
  }
  @include tablet {
    grid-template-columns: 230px minmax(0, 1fr);
  }

  grid-template-areas: "topbar topbar" "spinner spinner" "sidebar offers" "empty more";
  &.offersWidgetContainer {
    grid-template-areas: "topbar topbar" "spinner spinner" "offers offers" "empty more";
  }

  &.isMobile {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: "sidebar" "topbar" "spinner" "compareBar" "offers" "more";
    &.offersWidgetContainer {
      grid-template-areas: "topbar" "spinner" "compareBar" "offers" "more";
    }
    background: var(--yps-offerlist-mobile-bg-color);
  }

  margin: 0 auto;
  max-width: var(--yps-max-width);
  column-gap: 10px;

  .offerlistTopbar {
    grid-area: topbar;
    margin-top: 10px;

    @include mobile {
      margin-top: 0;
      border-width: 0;
      border-style: solid;
      border-color: var(--yps-border-color);
    }

    &__upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      @include mobile {
        padding: 10px;
      }
    }

    &__heading {
      &_2 {
        @include mobile {
          display: none;
        }
      }
    }

    &__usedFilters {
      margin-top: 10px;
    }
  }

  .sidebar {
    grid-area: sidebar;
    background: var(--yps-sidebar-background);
    border-radius: var(--yps-box-radius-sidebar);
    border: 1px solid var(--yps-sidebar-border-color);

    @include mobile {
      border-bottom: 1px solid var(--yps-border-color);
    }
    @include desktop {
      width: 300px;
    }

    @include small {
      width: 280px;
    }

    @include tablet {
      width: 230px;
    }
  }

  .top_button {
    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px;
      border: 0;
      button {
        padding: 0;
        border-color: var(--yps-offer-border-color);
        border-style: solid;
        border-width: 1px 1px 0 1px;
      }
      @include mobile {
        border: 0 !important;
        button {
          border: 0;
          font-size: 1em;
        }
      }
    }

    &__spacer {
      width: 1px;
      background: transparent;
      border-left: 1px solid var(--yps-border-color);
    }
  }

  .mapBtn {
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: auto;
    padding: 10px;
    border: none !important;
  }

  .offerlist {
    grid-area: offers;

    &__offers {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    &__offers__offersWidget {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 10px;

      > li {
        flex: 1 0 210px;
      }
    }
  }

  .moreButton {
    grid-area: more;
    margin: 10px 0 20px 0;
  }
}

.messageBlock {
  grid-area: offers;
  z-index: 1;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.matchedOffer {
  &__heading {
    padding: 5px 10px;
    background: var(--yps-offer-match-type-label-bg-color);
    color: var(--yps-offer-match-type-label-text-color);
    font-weight: 600;
    display: inline-block;
    border-radius: 5px 5px 0 0;
  }
  border-color: var(--yps-offer-match-type-border-color);
}
